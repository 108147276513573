html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  position: relative;
  margin: 0;
  padding: 0 0 66px 0;
  background-color: #fff;
  font-family: 'Circular', Arial, sans-serif;
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1.5;
  color: rgb(60,56,53);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  font-family: brother-1816, sans-serif;
}


/* Links */

a {
  color:#607485;
}

/* Typography */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #22292f;
}

p,
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

/* Buttons */

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
}

/* Images */

img {
  max-width: 100%;
  height: auto;
}

.content {
  position: relative;
  padding: 1.5rem 1.25rem;
}

/* Menu */

.menulink {
  display: none;
  padding: 0.25rem 0.625rem;
  font-size: 1rem;
  color: #718096;
  text-decoration: none;
  border-radius: 0.25rem;
}

.menulink:hover {
  background-color: #f5f5f5;
  color: #1a202c;
}

.menulink.active {
  color: #1a202c;
}

/* Nav */

.globalNav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 50px;
  z-index: 500;
  perspective: 2000px;
}

.globalNav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.globalNav a {
  color: #757575;
  text-decoration: none;
  transition: color 0.1s;
}

.globalNav a:hover {
  color: #222;
}

.globalNav .navRoot {
  position: relative;
}

.globalNav .navSection {
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 670px) {
  .globalNav .navSection {
    display: none;
  }
}

.globalNav .rootLink {
  display: inline-block;
  height: 50px;
  white-space: nowrap;
  user-select: none;
  line-height: 50px;
  margin: 0;
  padding: 0 10px;
}

@media (min-width: 670px) {
  .globalNav .rootLink {
    padding: 0 calc(10px + (100vw - 670px) * 0.040541);
  }
}

@media (min-width: 1040px) {
  .globalNav .rootLink {
    padding: 0 25px;
  }
}

.globalNav .colorize {
  color: #fff;
  transition: color 0.1s ease;
}

.globalNav .colorize.active,
.globalNav .colorize:hover {
  color: #32325d;
}

.globalNav .navSection .rootLink {
  position: relative;
  width: 50px;
  background-color: transparent;
  border-color: transparent;
  margin-right: 5px;
  cursor: pointer;
  outline: 0;
}

.globalNav .navSection .rootLink::after {
  content: 'MENY';
  display: block;
  position: absolute;
  left: 9px;
  bottom: -6px;
  color: #fff;
  font-size: 0.6875rem;
  line-height: 1;
}

.globalNav .navSection .rootLink h2 {
  color: inherit;
}

.globalNav .navSection .rootLink h2,
.globalNav .navSection .rootLink h2::after,
.globalNav .navSection .rootLink h2::before {
  position: absolute;
  width: 24px;
  height: 3px;
  border-radius: 1px;
  background: currentColor;
}

.globalNav .navSection .rootLink h2 {
  font-size: 0;
  left: 13px;
  top: 23px;
}

.globalNav .navSection .rootLink h2::after,
.globalNav .navSection .rootLink h2::before {
  content: '';
  left: 0;
}

.globalNav .navSection .rootLink h2::before {
  top: -9px;
}

.globalNav .navSection .rootLink h2::after {
  top: 9px;
}

.globalNav .popup {
  position: absolute;
  left: 10px;
  top: 5px;
  right: 10px;
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
  perspective: 2000px;
}

.globalNav .popupContainer {
  background: #fff;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
    0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  overflow: hidden;
  position: relative;
  font-size: 17px;
  line-height: 40px;
  white-space: nowrap;
  flex-grow: 1;
  transform: rotate3d(1, 1, 0, -15deg);
  transform-origin: 100% 0;
  opacity: 0;
  will-change: transform, opacity;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  transition-duration: 0.25s;
}

.globalNav .navSection.globalPopupActive .popupContainer {
  transform: none;
  opacity: 1;
  pointer-events: auto;
}

.globalNav .popup a {
  display: block;
}

.globalNav .popupCloseButton {
  position: absolute;
  right: 0;
  top: 0;
  width: 51px;
  height: 51px;
  cursor: pointer;
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border-color: transparent;
}

.globalNav .popupCloseButton::after,
.globalNav .popupCloseButton::before {
  content: '';
  position: absolute;
  background: #607485;
  border-radius: 1px;
  top: 24px;
  right: 14px;
  left: 14px;
  height: 3px;
  transform: rotate(45deg);
  transition: background 0.1s;
}

.globalNav .popupCloseButton::after {
  transform: rotate(-45deg);
}

.globalNav .popupCloseButton:hover::after,
.globalNav .popupCloseButton:hover::before {
  background: #32325d;
}

.globalNav .mobileSecondaryNav {
  padding: 35px 10px 30px;
  display: flex;
  flex-wrap: wrap;
}

.globalNav .mobileSecondaryNav > ul {
  flex-grow: 1;
}

.globalNav .mobileSecondaryNav > ul:last-child {
  flex-grow: 3;
}

.globalNav .mobileSecondaryNav a {
  padding: 0 20px;
  min-width: 100px;
}

/* Base */

@media (min-width: 576px) {
  .content {
    max-width: 804px;
    padding: 1.5rem;
  }
}

@media (min-width: 768px) {
  body {
    font-size: 1rem;
    line-height: 1.7;
    padding-bottom: 0;
  }

  .globalNav {
    display: none;
  }

  .container {
    margin-left: 220px;
  }

  .menulink {
    display: block;
  }
}

#root .Header_header__1VCKf {
  background-color: #607485;
  background-image: none;
  height: auto;
}

.logo-horizontal {
  width: 120px;
  padding-bottom: 80px;
}

.Schedule_calendar__itemActive__3mCER .Schedule_calendar__dayNumber__xLiqN {
  border-color: #E6A303;
  background-color: #E6A303;
}

body {
  background-color: #E3DFD9;
}
