.loaderHeader {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  height: 180px;
  padding: 1.5rem;
  background-image: linear-gradient(45deg, #00859c 0%, #06bbc8 100%);
}

.loaderContent {
  max-width: 540px;
  height: 160px;
  padding: 1.5rem;
}
