.skeleton__wrapper {
  display: flex;
  height: 100vh;
}

.skeleton__aside {
  display: none;
  flex: 0 0 220px;
  padding: 20px;
  z-index: 5;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.15);
}

.skeleton__main {
  flex: 1;
}

@media (min-width: 768px) {
  .skeleton__aside {
    display: block;
  }
}
